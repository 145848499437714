import { Link, NavLink } from "react-router-dom";
import { UserCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, Typography } from "@material-tailwind/react";
import { useContext, useEffect } from "react";
import { IconProps, Route, RoutePage } from "src/routes";
import { PowerIcon } from "@heroicons/react/24/solid";
import { UserContext } from "src/context/UserContext";
import { RouteContext } from "src/context/RouteContext";
import { MenuRouter } from "src/pages/dashboard";
import { Tenant, Menu } from "src/types/init";
import { TenantGraphServices } from "src/graphql/services/TenantGraphServices";
import { routes as initRoutes } from "src/routes";

export function Sidenav({
  className,
  brandName,
}: {
  brandName: string;
  className: string;
}) {
  const tenantGraphServices = new TenantGraphServices();
  const { logout } = useContext(UserContext);

  const { routes, init } = useContext(RouteContext);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await tenantGraphServices.gets();

    const tempMenus: RoutePage[] = [];

    res.tenants.map((tenant: Tenant, index: number) => {
      tenant.menus.map((menu: Menu, i: number) => {
        tempMenus.push({
          icon: <UserCircleIcon {...IconProps} />,
          name: menu.name,
          path: "/menu/*",
          to:
            menu.isPromotion == true
              ? "/menu/service/" + menu.id
              : "/menu/product/" + menu.id,
          element: <MenuRouter />,
        });
      });
    });

    init([
      {
        layout: "dashboard",
        pages: [...tempMenus, initRoutes[0].pages[0]],
      },
    ]);
  }

  return (
    <div
      className={`${className} bg-white shadow-sm my-4 ml-4 h-auto rounded-xl transition-transform duration-300 xl:translate-x-0 border border-blue-gray-100`}
    >
      <div className={`relative`}>
        <button
          onClick={() => {
            window.location.href = "/dashboard/menu";
          }}
          className="py-6 px-8 text-center flex justify-center mx-auto"
        >
          <Typography variant="h5" color={"blue-gray"}>
            {/* {brandName} */}
            Magic Billiard
          </Typography>
          {/* <img src="/images/gymale.png" className="h-8" /> */}
        </button>
        <IconButton
          variant="text"
          color="black"
          size="sm"
          ripple={false}
          className="absolute right-0 top-0 grid rounded-br-none rounded-tl-none xl:hidden"
          onClick={() => {}}
        >
          <XMarkIcon strokeWidth={2.5} className="h-5 w-5 text-dark" />
        </IconButton>
      </div>
      <div className="m-4">
        {routes &&
          routes.map(({ layout, pages }, key) => (
            <ul key={key} className="mb-4 flex flex-col gap-1">
              {pages.map(({ icon, name, path, type, to }) =>
                type === "private" ? null : (
                  <li key={name}>
                    <NavLink to={`/${layout}${to}`}>
                      {({ isActive }) => (
                        <Button
                          variant={isActive ? "gradient" : "text"}
                          className="flex items-center gap-4 px-4 capitalize"
                          fullWidth
                        >
                          {icon}
                          <Typography
                            color="inherit"
                            className="font-medium capitalize"
                          >
                            {name}
                          </Typography>
                        </Button>
                      )}
                    </NavLink>
                  </li>
                )
              )}
              <li key={"logout"}>
                <NavLink to={`/${layout}${"/logout"}`} onClick={logout}>
                  {({ isActive }) => (
                    <Button
                      variant={isActive ? "gradient" : "text"}
                      className="flex items-center gap-4 px-4 capitalize"
                      fullWidth
                    >
                      <PowerIcon {...IconProps} />
                      <Typography
                        color="inherit"
                        className="font-medium capitalize"
                      >
                        {"logout"}
                      </Typography>
                    </Button>
                  )}
                </NavLink>
              </li>
            </ul>
          ))}
      </div>
    </div>
  );
}

export default Sidenav;

import axios from "axios";
import { REACT_APP_GRAPHQL_API_URL } from "src/configs/url";
import { handleAxiosError } from "src/services/errors";
import { headersApiKey } from "src/services/config";
import { CREATE_ORDER } from "../mutation/CreateOrder";
import { CREATE_ORDER_CASH } from "../mutation/CreateOrderCash";

export class OrderGraphServices {
  async createOrder({ orderCustomer }: { orderCustomer: any }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: CREATE_ORDER, variables: { input: orderCustomer } },
        {
          headers: headersApiKey,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  async createOrderCash({ orderCustomer }: { orderCustomer: any }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: CREATE_ORDER_CASH, variables: { input: orderCustomer } },
        {
          headers: headersApiKey,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}

export const CREATE_ORDER_CASH = `
  mutation Mutation($input: CreateCashOrderCustomerInput!) {
    createCashOrderCustomer(input: $input) {
      orderCustomer {
        id
        orderType
        totalAmount
        totalQty
      }
    }
  }
`;

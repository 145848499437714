import { createContext, useState } from "react";
import React from "react";
import { Route } from "src/routes";

interface RouteContextType {
  init: (routes: Route[]) => void;
  routes: Route[];
}

export const RouteContext = createContext<RouteContextType>({
  init: (routes: Route[]) => {},
  routes: [] as Route[],
});

export const RouteProvider: React.FC<React.PropsWithChildren> = ({
  children,
}): React.ReactElement => {
  const [routes, setRoutes] = useState<Route[]>([]);

  const init = (routes: Route[]) => {
    setRoutes(routes);
  };

  return (
    <RouteContext.Provider value={{ init, routes }}>
      {children}
    </RouteContext.Provider>
  );
};

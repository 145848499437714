import { REACT_APP_API_KEY } from "src/configs/url";

// Get all cookies
const allCookies = document.cookie;

// To make it more usable, you can parse the cookies into an object
function parseCookies() {
  const cookies = {} as any;
  document.cookie.split(";").forEach((cookie) => {
    const [name, value] = cookie.trim().split("=");
    cookies[name] = value;
  });
  return cookies;
}

export const cookies = parseCookies();

export const headers = {
  Authorization: `Bearer ${cookies.token}`,
  "Content-Type": "application/json", // Optional, set the content type if needed
};

export const headersApiKey = {
  "x-api-key": REACT_APP_API_KEY,
};

export const headersAuth = {
  Authorization: `Bearer ${cookies.token}`,
  "Content-Type": "application/json", // Optional, set the content type if needed
};

export const headersFormData = {
  Authorization: `Bearer ${cookies.token}`,
  "Content-Type": "multipart/form-data",
};

export const headersDownload = {
  Authorization: `Bearer ${cookies.token}`,
};

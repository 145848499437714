import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Select,
  Option,
  Typography,
} from "@material-tailwind/react";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { ProductCart, ProductList, Submenu } from "src/types/init";
import { RupiahFormat } from "src/utils/RupiahFormat";
import { CartContext } from "src/context/CartContext";
import { SubmenuGraphServices } from "src/graphql/services/SubmenuGraphServices";
import { ProductGraphServices } from "src/graphql/services/ProductGraphServices";
import { GiElectric } from "react-icons/gi";
import { ProductServices } from "src/services/ProductServices";
import { v4 as uuidv4 } from "uuid";

export function MenuServiceListPage() {
  const navigate = useNavigate();
  const params = useParams();
  const menuId = params["*"]?.split("/")[1] ?? "";

  const submenuGraphServices = new SubmenuGraphServices();

  const { addToCart, cart } = useContext(CartContext);

  const [submenus, setSubmenus] = useState([]);

  const [productActive, setProductActive] = useState<ProductList | null>(null);

  useEffect(() => {
    fetch();
  }, [menuId]);

  async function fetch() {
    const resSubmenu = await submenuGraphServices.gets({ menuId });

    setSubmenus(resSubmenu.submenus);
  }

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12 min-h-[calc(100vh-200px)]">
      <div className="grid grid-cols-12 gap-6">
        {productActive && (
          <DialogDefault
            product={productActive}
            handleCancel={() => {
              setProductActive(null);
            }}
            handleAdd={(temp: ProductCart) => {
              if (cart.products.length > 0) {
                toast.error("Please empty cart first");
                return;
              } else {
                addToCart(temp);
                toast.success("Success add to cart");
              }
              setProductActive(null);
            }}
          />
        )}

        {submenus.map((item: Submenu) => {
          return (
            <SubmenuComponent
              key={item.id}
              submenu={item}
              handleProductActive={(product: ProductList) => {
                setProductActive(product);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

function SubmenuComponent({
  submenu,
  handleProductActive,
}: {
  submenu: Submenu;
  handleProductActive: any;
}) {
  const productGraphServices = new ProductGraphServices();
  const keyHide = "[HIDDEN_PRODUCT]-";

  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const res = await productGraphServices.gets({ submenuId: submenu.id });

    setProducts(res.products);
  }

  return (
    <div className="col-span-12 grid grid-cols-12 gap-6">
      {products.map((item: ProductList) => {
        if (item.name.includes(keyHide)) return null;

        const start_timestamp = new Date(item.state?.start_timestamp ?? "");
        const now = new Date();
        const diff = now.getTime() - start_timestamp.getTime();
        const diffMinutes = Math.ceil(diff / (1000 * 60));

        return (
          <button
            key={item.id}
            className="mt-6 col-span-3 group"
            onClick={() => {
              handleProductActive(item);
            }}
          >
            <Card className="group-hover:bg-gray-300">
              <CardHeader color="blue-gray" className="relative h-36">
                <img
                  src={item.displayPic}
                  alt="card-image"
                  className="object-cover w-full h-full rounded-lg"
                />
              </CardHeader>
              <CardBody className="h-fit">
                <Typography variant="h6" color="blue-gray" className="mb-1">
                  {item.name}
                </Typography>
                {item.state?.status == "active" ? (
                  <Typography className="text-sm mb-1 text-green-400">
                    {diffMinutes} Minutes
                  </Typography>
                ) : (
                  <Typography className="text-sm mb-1">Table Closed</Typography>
                )}
                <Typography className="text-sm">
                  {item.description.length > 50
                    ? item.description.substring(0, 50) + "..."
                    : item.description}
                </Typography>
              </CardBody>
            </Card>
          </button>
        );
      })}
    </div>
  );
}

function DialogDefault({
  product,
  handleCancel,
  handleAdd,
}: {
  product: ProductList | null;
  handleCancel: () => void;
  handleAdd: (temp: ProductCart) => void;
}) {
  const productServices = new ProductServices();
  const [count, setCount] = useState(1);
  const [note, setNote] = useState("");
  const [variant, setVariant] = useState(product?.variants?.[0]?.id ?? "");

  return (
    <div className="bg-black bg-opacity-50 fixed top-0 left-0 h-full w-full z-50 flex justify-center items-center">
      <Card className="group-hover:bg-gray-300 flex flex-row">
        <CardHeader color="blue-gray" className="relative h-64">
          <img
            src={product!.displayPic}
            alt="card-image"
            className="object-cover w-full h-full rounded-lg"
          />
        </CardHeader>

        <CardBody className="h-fit">
          <Typography variant="h6" color="blue-gray" className="mb-1">
            {product!.name}
          </Typography>
          <Typography variant="h6" color="orange" className="mb-1">
            {RupiahFormat(
              product!.originalPrice +
                (product?.variants?.find((item) => item.id == variant)
                  ?.originalPrice ?? 0)
            )}
          </Typography>
          <Typography className="text-sm mb-1">
            Stock : {product!.stock > 1000 ? "Unlimited" : product!.stock}
          </Typography>
          <Typography className="text-sm">{product!.description}</Typography>

          {product?.variants?.length || 0 > 0 ? (
            <div className="my-4">
              <select
                onChange={(e) => {
                  setVariant(e.target.value);
                }}
                value={variant}
                className="border border-blue-gray-200 focus:border-gray-900 py-3 px-2 w-full rounded-lg text-sm text-blue-gray-400"
              >
                {product?.variants?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}

          <div className="my-4">
            <Input
              value={note}
              crossOrigin={note.toString()}
              label="note"
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </div>

          <div className="flex gap-4">
            <Button
              onClick={async () => {
                if (product?.state?.status == "inactive") {
                  const additionalData = {
                    state: {
                      table: product?.state?.table,
                      status: "active",
                      reservation_status: "OB",
                      duration: 0,
                      start_timestamp: new Date().toISOString(),
                      session_id: uuidv4(),
                      customer_name: "Free Play",
                    },
                    variant_mapping: product?.additionalData?.variant_mapping,
                  };

                  const resUpdate = await productServices.update({
                    id: product!.id,
                    additionalData: JSON.stringify(additionalData),
                  });
                  toast.success("Table Open Success");
                } else {
                  const now = new Date();
                  const start = new Date(product?.state?.start_timestamp ?? "");
                  const diff = now.getTime() - start.getTime();
                  const diffMinutes = Math.ceil(diff / (1000 * 60));

                  const additionalData = {
                    state: {
                      table: product?.state?.table,
                      status: "inactive",
                      reservation_status: "OB",
                      duration: diffMinutes,
                      start_timestamp: product?.state?.start_timestamp,
                      session_id: product?.state?.session_id,
                      customer_name: "Free Play",
                    },
                    variant_mapping: product?.additionalData?.variant_mapping,
                  };

                  const resUpdate = await productServices.update({
                    id: product!.id ?? "",
                    additionalData: JSON.stringify(additionalData),
                  });
                  toast.success("Table Close Success");
                }
              }}
              className={
                product?.state?.status == "active"
                  ? "bg-orange-500 border-2 text-white border-orange-500 outline-none"
                  : "bg-white border-2 text-orange-500 border-orange-500 outline-none"
              }
            >
              <GiElectric size={20} />
            </Button>
            <Button
              variant="outlined"
              onClick={handleCancel}
              className="w-full"
            >
              Cancel
            </Button>

            {(product?.state?.reservation_status == "RB" &&
              product?.state?.status == "active") ||
            (product?.state?.reservation_status == "OB" &&
              product?.state?.status == "active" &&
              product?.variants?.find((v) => v.id == variant)?.name !=
                "Open Bill") ? (
              <Button size="lg" disabled className="w-full">
                Reserved
              </Button>
            ) : (
              <Button
                size="lg"
                className="w-full"
                onClick={() => {
                  const now = new Date();
                  const start = new Date(product?.state?.start_timestamp ?? "");
                  const diff = now.getTime() - start.getTime();
                  const diffMinutes = Math.ceil(diff / (1000 * 60));
                  const hours = Math.ceil(diffMinutes / 60);

                  const length = diffMinutes < 60 ? 60 : diffMinutes;

                  const temp = {
                    id: new Date().getTime().toString(),
                    product: product!,
                    qty:
                      product?.state?.reservation_status == "OB" &&
                      product?.state?.status == "active" &&
                      product?.variants?.find((v) => v.id == variant)?.name ==
                        "Open Bill"
                        ? length
                        : count,
                    note: note,
                    buy: true,
                    variant: variant,
                  } as ProductCart;

                  handleAdd(temp);
                }}
              >
                Add to Cart
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

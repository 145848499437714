import { ChartBarIcon } from "@heroicons/react/24/outline";
import { UserCircleIcon, PlayIcon } from "@heroicons/react/24/solid";
import { ProfilePage, MenuRouter } from "src/pages/dashboard";
import { PlayRouter } from "./pages/dashboard/play";
import { FaBowlFood } from "react-icons/fa6";
import { RiBilliardsFill } from "react-icons/ri";

export const IconProps = {
  className: "w-5 h-5 text-inherit",
};

export interface Route {
  layout?: string;
  pages: RoutePage[];
}

export interface RoutePage {
  icon: React.ReactNode;
  name: string;
  path: string;
  to: string;
  element: React.ReactNode;
  type?: "private";
}

export const routes: Route[] = [
  {
    layout: "dashboard",
    pages: [
      // {
      //   icon: <PlayIcon {...IconProps} />,
      //   name: "play",
      //   path: "/play/*",
      //   to: "/play",
      //   element: <PlayRouter />,
      // },
      {
        icon: <UserCircleIcon {...IconProps} />,
        name: "profile",
        path: "/profile",
        to: "/profile",
        element: <ProfilePage />,
      },
    ],
  },
];

export default routes;

import { Routes, Route } from "react-router-dom";
import { Sidenav, DashboardNavbar, Footer } from "src/widgets/layout";
import routes from "src/routes";
import { Toaster } from "react-hot-toast";
import { useContext } from "react";
import { RouteContext } from "src/context/RouteContext";

export function DashboardLayout() {
  const { routes } = useContext(RouteContext);

  return (
    <div className="min-w-screen bg-blue-gray-50/50 grid grid-cols-12 gap-4">
      <Sidenav className="col-span-3" brandName="Dashboard" />
      <div className="col-span-9 p-4">
        <DashboardNavbar />
        <Routes>
          {routes.map(
            ({ layout, pages }) =>
              layout === "dashboard" &&
              pages.map(({ path, element }) => (
                <Route path={path} element={element} />
              ))
          )}
        </Routes>
        <div className="text-blue-gray-600">
          <Footer />
        </div>
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
    </div>
  );
}

export default DashboardLayout;

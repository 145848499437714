import { Route, Routes, useParams } from "react-router-dom";
import { MenuProductListPage } from "./product-list";
import { MenuServiceListPage } from "./service-list";
import { MenuProductCartPage } from "./product-cart";
import { MenuServiceCartPage } from "./service-cart";
import { MenuProductInformationPage } from "./product-information";
import { MenuServiceInformationPage } from "./service-information";
import { MenuProductPaymentPage } from "./product-payment";
import { MenuServicePaymentPage } from "./service-payment";
import { useContext } from "react";
import { RouteContext } from "src/context/RouteContext";
import { HomePage } from "../home";

export function MenuRouter() {
  const params = useParams();
  const menuId = params["*"]?.split("/")[1] ?? "";
  const { routes } = useContext(RouteContext);

  return (
    <div className="mt-0 min-h-[calc(100vh-160px)]">
      <Routes>
        <Route path="/product/cart" element={<MenuProductCartPage />} />
        <Route path="/service/cart" element={<MenuServiceCartPage />} />
        <Route
          path="/product/information"
          element={<MenuProductInformationPage />}
        />
        <Route
          path="/service/information"
          element={<MenuServiceInformationPage />}
        />
        <Route path="/product/payment" element={<MenuProductPaymentPage />} />
        <Route path="/service/payment" element={<MenuServicePaymentPage />} />
        <Route path="/product/:menuId" element={<MenuProductListPage />} />
        <Route path="/service/:menuId" element={<MenuServiceListPage />} />

        {/* <Route path="*" element={<HomePage />} /> */}
      </Routes>
    </div>
  );
}

import { Button, Input, Typography } from "@material-tailwind/react";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CartContext } from "src/context/CartContext";
import { OrderContext } from "src/context/OrderContext";
import { MemberServices } from "src/services/MemberServices";
import { ProductServices } from "src/services/ProductServices";
import { Order, ProductCart } from "src/types/init";
import { v4 as uuidv4 } from "uuid";

export function MenuServiceInformationPage() {
  const navigate = useNavigate();
  const productServices = new ProductServices();

  const { cart } = useContext(CartContext);
  const { order, editOrder } = useContext(OrderContext);

  const [information, setInformation] = useState<Order>({
    tableNumber: "-",
    orderType: "DINE_IN",
    customerPhone: "",
    memberCardNumber: "",
    name: cart.products[0].product.state?.customer_name ?? "",
    orderInput: [],
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInformation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const orderInput = cart.products.map((product: ProductCart) => {
      return {
        product: product.product,
        variant: product.product.variants?.find(
          (variant) => variant.id == product.variant
        ),
        quantity: product.qty,
        notes: product.note,
      };
    });

    const orderTemp = {
      ...information,
      orderInput: orderInput,
    };

    editOrder(orderTemp);

    if (
      cart.products[0].product.variants?.find(
        (variant) => variant.id == cart.products[0].variant
      )?.name == "Open Bill" &&
      orderInput[0]?.product?.state?.status == "inactive"
    ) {
      const additionalData = {
        state: {
          table: orderInput[0]?.product?.state?.table,
          status: "active",
          reservation_status: "OB",
          duration: 0,
          start_timestamp: new Date().toISOString(),
          session_id: uuidv4(),
          customer_name: information.name,
        },
        variant_mapping:
          orderInput[0]?.product?.additionalData?.variant_mapping,
        // variant_mapping: {
        //   "1 Jam": 60,
        //   "2 Jam": 120,
        //   "3 Jam": 180,
        //   "Open Bill": 0,
        //   "Happy 1+1": 120,
        //   "Happy 2+1": 180,
        // },
      };

      const resUpdate = await productServices.update({
        id: orderInput[0].product.id,
        additionalData: JSON.stringify(additionalData),
      });
      toast.success("Order Open Bill Success");

      setTimeout(() => {
        window.location.href = "/dashboard/menu";
      }, 2000);
    } else {
      navigate("/dashboard/menu/service/payment");
    }
  };

  return (
    <div className="flex flex-col gap-12 min-h-[calc(100vh-200px)]">
      <div className="grid grid-cols-12 gap-4 mb-8">
        <div className="bg-white shadow-lg rounded-xl p-10 col-span-6">
          <Typography variant="h6" color="gray">
            Information Customer
          </Typography>
          <form className="mt-8 mb-2" onSubmit={handleSubmit}>
            <div className="mb-1 flex flex-col gap-5 lg:col-span-1 col-span-2">
              <Input
                name="orderType"
                value={information.orderType}
                crossOrigin={information.orderType.toString()}
                label="Order Type"
                readOnly={true}
                onChange={handleChange}
              />

              <Input
                name="name"
                value={information.name}
                crossOrigin={information.name.toString()}
                label="Name"
                onChange={handleChange}
              />
            </div>

            <div className="flex mt-8 gap-4">
              <Button size="lg" type="submit">
                Payment
              </Button>

              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

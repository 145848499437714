export const GET_TENANTS = `
  query Tenants {
    tenants {
      id
      menus {
        name
        isPromotion
        id
        displayPic
      }
      name
    }
  }
`;

export const CREATE_QRIS_TRANSACTION = `
  mutation CreateQrisTransaction($input: CreateQrisTransactionInput!) {
    createQrisTransaction(input: $input) {
      transaction {
        id
        url
      }
    }
  }
`;

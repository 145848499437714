import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
  Button,
} from "@material-tailwind/react";
import { useContext } from "react";
import { FiTrash } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { CartContext } from "src/context/CartContext";
import { ProductCart } from "src/types/init";
import { RupiahFormat } from "src/utils/RupiahFormat";

export function MenuServiceCartPage() {
  const navigate = useNavigate();

  const { cart, removeFromCart } = useContext(CartContext);

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12 min-h-[calc(100vh-200px)]">
      <Card>
        <CardHeader
          variant="gradient"
          color="gray"
          className="mb-8 p-6 flex items-center justify-between"
        >
          <Typography variant="h6" color="white">
            Cart Product
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          <table className="w-full min-w-[640px] table-auto">
            <thead>
              <tr>
                {[
                  "id",
                  "photo",
                  "name",
                  "qty",
                  "note",
                  "variant",
                  "price",
                  "total price",
                ].map((el) => (
                  <th
                    key={el}
                    className="border-b border-blue-gray-50 py-3 px-5 text-left"
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {el}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {cart.products.map((item: ProductCart, index: number) => {
                const className = `py-3 px-5 ${
                  parseInt(item.id) == cart.products.length - 1
                    ? ""
                    : "border-b border-blue-gray-50"
                }`;

                return (
                  <tr key={index} className="">
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {index + 1}
                      </Typography>
                    </td>
                    <td className={className}>
                      <td className={className}>
                        <div className="flex items-center">
                          <Avatar
                            src={item.product.displayPic}
                            alt={item.product.name}
                            size="lg"
                            variant="rounded"
                          />
                        </div>
                      </td>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {item.product.name}
                      </Typography>
                      <Typography className="text-xs text-blue-gray-600">
                        {item.product.description}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {item.qty}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs text-blue-gray-600">
                        {item.note ?? "-"}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {item.product.variants?.find(
                          (variant) => variant.id == item.variant
                        )?.name ?? "-"}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {item.variant == ""
                          ? RupiahFormat(item.product.originalPrice)
                          : RupiahFormat(
                              item.product.variants?.find(
                                (variant) => variant.id == item.variant
                              )?.originalPrice ?? 0
                            )}
                      </Typography>
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {item.variant == ""
                          ? RupiahFormat(item.product.originalPrice * item.qty)
                          : RupiahFormat(
                              (item.product.variants?.find(
                                (variant) => variant.id == item.variant
                              )?.originalPrice ?? 0) * item.qty
                            )}
                      </Typography>
                    </td>
                    <td className={`${className}`}>
                      <Button
                        size="sm"
                        color="red"
                        onClick={() => {
                          removeFromCart(item.id);
                        }}
                      >
                        <FiTrash />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>

      <Card>
        <CardHeader
          variant="gradient"
          color="gray"
          className="p-6 flex items-center justify-between"
        >
          <Typography variant="h6" color="white">
            Total Payment
          </Typography>

          <Button
            size="sm"
            color="white"
            onClick={() => {
              const checkTable = cart.products.find((item) => {
                return item.product.state?.table != null;
              });

              if (checkTable) {
                navigate("/dashboard/menu/service/information");
              } else {
                navigate("/dashboard/menu/product/information");
              }
            }}
          >
            Continue
          </Button>
        </CardHeader>
        <CardBody className="">
          <div>
            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-12">
                <div className="col-span-5">
                  <h5 className="f-h5">Sub Total</h5>
                </div>
                <div className="col-span-7">
                  <h5 className="f-p1-r">
                    {RupiahFormat(
                      cart.products.reduce((acc, item) => {
                        return (
                          acc +
                          (item.product.originalPrice +
                            (item.product.variants?.find(
                              (variant) => variant.id == item.variant
                            )?.originalPrice ?? 0)) *
                            item.qty
                        );
                      }, 0)
                    )}
                  </h5>
                </div>
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-5">
                  <h5 className="f-h5">Tax</h5>
                </div>
                <div className="col-span-7">
                  <h5 className="f-p1-r">
                    {RupiahFormat(
                      cart.products.reduce((acc, item) => {
                        return (
                          acc +
                          (item.product.price +
                            (item.product.variants?.find(
                              (variant) => variant.id == item.variant
                            )?.price ?? 0)) *
                            item.qty
                        );
                      }, 0) -
                        cart.products.reduce((acc, item) => {
                          return (
                            acc +
                            (item.product.originalPrice +
                              (item.product.variants?.find(
                                (variant) => variant.id == item.variant
                              )?.originalPrice ?? 0)) *
                              item.qty
                          );
                        }, 0)
                    )}
                  </h5>
                </div>
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-5">
                  <h5 className="f-h5 font-bold">Total</h5>
                </div>
                <div className="col-span-7">
                  <h5 className="f-p1-r font-bold">
                    {RupiahFormat(
                      cart.products.reduce((acc, item) => {
                        return (
                          acc +
                          (item.product.price +
                            (item.product.variants?.find(
                              (variant) => variant.id == item.variant
                            )?.price ?? 0)) *
                            item.qty
                        );
                      }, 0)
                    )}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

import { createContext, useState } from "react";
import { OrderInput, Order } from "src/types/init";
import React from "react";

interface OrderContextType {
  editOrder: (updatedOrder: Order) => void;
  order: Order;
}

export const OrderContext = createContext<OrderContextType>({
  editOrder: (updatedOrder: Order) => {},
  order: {} as Order,
});

export const OrderProvider: React.FC<React.PropsWithChildren> = ({
  children,
}): React.ReactElement => {
  const [order, setOrder] = useState<Order>({
    tableNumber: "-",
    orderType: "DINE_IN",
    customerPhone: "",
    memberCardNumber: "",
    name: "",
    orderInput: [],
  });

  const editOrder = (updatedOrder: Order) => {
    setOrder({ ...updatedOrder });
  };

  return (
    <OrderContext.Provider value={{ editOrder, order }}>
      {children}
    </OrderContext.Provider>
  );
};

import axios from "axios";
import { REACT_APP_GRAPHQL_API_URL } from "src/configs/url";
import { handleAxiosError } from "src/services/errors";
import { GET_TENANTS } from "../query/Tenant";
import { headersApiKey } from "src/services/config";

export class TenantGraphServices {
  async gets() {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: GET_TENANTS },
        {
          headers: headersApiKey,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}

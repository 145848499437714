import { useMutation } from "@apollo/client";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
  Button,
  Select,
  Option,
  Input,
} from "@material-tailwind/react";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { CartContext } from "src/context/CartContext";
import { OrderContext } from "src/context/OrderContext";
import { UserContext } from "src/context/UserContext";
import { OrderGraphServices } from "src/graphql/services/OrderGraphServices";
import { TransactionGraphServices } from "src/graphql/services/TransactionGraphServices";
import { PrinterServices } from "src/services/PrinterServices";
import { ProductServices } from "src/services/ProductServices";
import { Cart, Order, ProductCart, User } from "src/types/init";
import { RupiahFormat } from "src/utils/RupiahFormat";
import { v4 as uuidv4 } from "uuid";

export function MenuServicePaymentPage() {
  const transactionGraphServices = new TransactionGraphServices();
  const orderGraphServices = new OrderGraphServices();
  const productServices = new ProductServices();

  const { cart } = useContext(CartContext);
  const { order, editOrder } = useContext(OrderContext);
  const { user } = useContext(UserContext);

  const [qrisQR, setQrisQR] = useState(null);
  const [actionClick, setActionClick] = useState(false);
  const [cashActionComponent, setCashActionComponent] = useState(false);

  async function handlePaymentCash({ moneyAmount }: { moneyAmount: number }) {
    if (
      moneyAmount <
      cart.products.reduce(
        (acc, product) =>
          acc +
          product.product.price +
          (product.product.variants?.find(
            (variant) => variant.id == product.variant
          )?.price || 0) *
            product.qty,
        0
      )
    ) {
      toast.error("Money Amount is not enough");
      setActionClick(false);
      return;
    }

    if (order.orderInput.length == 0) {
      toast.error("Cart is empty");
      setActionClick(false);
      return;
    }

    const res = await orderGraphServices.createOrderCash({
      orderCustomer: {
        orderInput: order.orderInput.map((orderInput) => {
          return {
            productId: orderInput.product.id,
            productVariantId: orderInput.variant?.id,
            quantity: orderInput.quantity,
            notes: orderInput.notes,
          };
        }),
        tableNumber: order.tableNumber,
        orderType: order.orderType,
        customerPhone: order.customerPhone,
        memberCardNumber: order.memberCardNumber,
      },
    });

    let additionalData = {};

    if (
      order.orderInput[0].product.state?.reservation_status == "OB" &&
      order.orderInput[0].product.state?.status == "active"
    ) {
      const now = new Date();
      const start = new Date(
        order.orderInput[0].product.state?.start_timestamp ?? ""
      );
      const diff = now.getTime() - start.getTime();
      const diffMinutes = Math.ceil(diff / (1000 * 60));

      additionalData = {
        state: {
          table: order?.orderInput[0]?.product?.state?.table,
          status: "inactive",
          reservation_status: "OB",
          duration: diffMinutes,
          start_timestamp:
            order?.orderInput[0]?.product?.state?.start_timestamp,
          session_id: order?.orderInput[0]?.product?.state?.session_id,
          customer_name: order.name,
        },
        variant_mapping:
          order?.orderInput[0]?.product?.additionalData?.variant_mapping,
      };
    } else {
      additionalData = {
        state: {
          table: order?.orderInput[0]?.product?.state?.table,
          status: "active",
          reservation_status: "RB",
          duration:
            order?.orderInput[0]?.product?.additionalData?.variant_mapping[
              order?.orderInput[0]?.variant?.name ?? ""
            ],
          start_timestamp: new Date().toISOString(),
          session_id: uuidv4(),
          customer_name: order.name,
        },
        variant_mapping:
          order?.orderInput[0]?.product?.additionalData?.variant_mapping,
      };
    }

    const resUpdate = await productServices.update({
      id: order.orderInput[0].product.id,
      additionalData: JSON.stringify(additionalData),
    });

    toast.success("Order Success");

    await handlePrint({
      resOrderId: res.createCashOrderCustomer.orderCustomer.id,
      cart: cart,
      typePayment: "CASH",
      moneyAmount: moneyAmount,
      order: order,
      user: user,
      customer: order.name,
    });

    setTimeout(() => {
      handleBackHome();
    }, 2000);
  }

  async function handlePaymentQris() {
    const resOrder = await orderGraphServices.createOrder({
      orderCustomer: {
        orderInput: order.orderInput,
        tableNumber: order.tableNumber,
        orderType: order.orderType,
        customerPhone: order.customerPhone,
        memberCardNumber: order.memberCardNumber,
      },
    });

    const resQris = await transactionGraphServices.createQrisTransaction({
      orderCustomerId: resOrder.createOrderCustomer.orderCustomer.id,
    });

    const tempQR = resQris.createQrisTransaction.transaction.url;

    setQrisQR(tempQR);

    handlePrint({
      resOrderId: resOrder.createOrderCustomer.orderCustomer.id,
      cart: cart,
      typePayment: "QRIS",
      moneyAmount: resOrder.createOrderCustomer.orderCustomer.totalAmount,
      order: order,
      user: user,
      customer: order.name,
    });

    handleWaiting({
      id: resQris.createQrisTransaction.transaction.id,
    });
  }

  async function handleWaiting({ id }: { id: string }) {
    const res = await transactionGraphServices.isTransactionSettled({ id });

    if (res?.isTransactionSettled == true) {
      toast.success("Order Success");
      handleBackHome();
    } else {
      setTimeout(() => {
        handleWaiting({ id });
      }, 2000);
    }
  }

  function handleBackHome() {
    window.location.href = "/";
  }

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12 min-h-[calc(100vh-200px)]">
      {cashActionComponent && (
        <CashActionComponent
          callback={(moneyAmont) => {
            if (actionClick) return;
            setActionClick(true);
            handlePaymentCash({ moneyAmount: moneyAmont });
          }}
          cancel={() => {
            setCashActionComponent(false);
          }}
        />
      )}
      <Card>
        <CardHeader
          variant="gradient"
          color="gray"
          className="p-6 flex items-center justify-between"
        >
          <Typography variant="h6" color="white">
            Payment
          </Typography>
        </CardHeader>
        <CardBody>
          <div>
            <Typography>
              <span className="font-bold">Total Payment:</span>{" "}
              {RupiahFormat(
                cart.products.reduce(
                  (acc, product) =>
                    acc +
                    (product.product.price +
                      (product.product.variants?.find(
                        (variant) => variant.id == product.variant
                      )?.price || 0)) *
                      product.qty,
                  0
                )
              )}
            </Typography>
          </div>

          <div className="mt-6 flex gap-4">
            <Button
              disabled={actionClick}
              size="lg"
              onClick={() => {
                setCashActionComponent(true);
              }}
            >
              Cash
            </Button>
            <Button
              disabled={true}
              size="lg"
              onClick={() => {
                handlePaymentQris();
              }}
            >
              QRIS
            </Button>
            <Button size="lg" disabled={true}>
              Point
            </Button>
          </div>

          {qrisQR && (
            <div className="mt-6 flex flex-col items-center">
              <Typography variant="h5">SCAN QRIS</Typography>
              <img className="w-96" alt="qris" src={qrisQR} />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

async function handlePrint({
  resOrderId,
  typePayment,
  moneyAmount,
  cart,
  order,
  user,
  customer,
}: {
  resOrderId: string;
  typePayment: string;
  moneyAmount: number;
  cart: Cart;
  order: Order;
  user: User;
  customer: any;
}) {
  // return;
  const printerServices = new PrinterServices();

  let printing = [];

  if (user.email == "manager@gymale.com") {
    printing = ["DISMORE_CUSTOMER1", "DISMORE_CUSTOMER1"];
  } else {
    printing = ["DISMORE_CUSTOMER1", "DISMORE_CUSTOMER1"];
  }

  const machine = user.userRole == "tenant" ? "Self Service" : "Cashier";

  const subTotal = cart.products.reduce(
    (acc, product) =>
      acc +
      (product.product.originalPrice +
        (product.product.variants?.find(
          (variant) => variant.id == product.variant
        )?.originalPrice || 0)) *
        product.qty,
    0
  );

  const total = cart.products.reduce(
    (acc, product) =>
      acc +
      (product.product.price +
        (product.product.variants?.find(
          (variant) => variant.id == product.variant
        )?.price || 0)) *
        product.qty,
    0
  );

  const time = new Date().toLocaleString("id-ID", {
    timeZone: "Asia/Jakarta",
  });

  const tempOrders = cart.products.map((product: ProductCart) => {
    return {
      id: product.product.id,
      name: product.product.name,
      amount: product.qty,
      note: product.note,
      originalPrice:
        product.product.originalPrice +
        (product.product.variants?.find(
          (variant) => variant.id == product.variant
        )?.originalPrice || 0),
      totalPrice:
        (product.product.originalPrice +
          (product.product.variants?.find(
            (variant) => variant.id == product.variant
          )?.originalPrice || 0)) *
        product.qty,
    };
  });

  const tax = total - subTotal;
  const json = {
    printing: printing,
    moneyAmount: RupiahFormat(moneyAmount),
    returnAmount:
      typePayment == "QRIS"
        ? RupiahFormat(0)
        : RupiahFormat(moneyAmount - total),
    idOrderCustomer: resOrderId,
    typePayment: typePayment,
    user: order,
    orderTime: time,
    machine: machine,
    orders: tempOrders,
    subTotal: RupiahFormat(subTotal),
    discount: RupiahFormat(0),
    tax: RupiahFormat(tax),
    total: RupiahFormat(subTotal + tax),
    repeatOrderUrl: "",
  };

  const res = await printerServices.submitPrintJob({ json, id: resOrderId });

  return <></>;
}

export function CashActionComponent({
  callback,
  cancel,
}: {
  callback: (moneyAmount: number) => void;
  cancel: Function;
}) {
  const [moneyAmount, setMoneyAmount] = useState(0);

  return (
    <div
      className={`visible fixed bg-black bg-opacity-40 left-0 top-0 w-full h-full z-50 flex justify-center items-center`}
    >
      <div className="max-h[calc(100vh-5em)] h-fit max-w-lg bg-white p-5 rounded-xl text-center">
        <Typography variant="h5" className="mt-0 font-bold">
          Cash Payment
        </Typography>
        <Typography variant="paragraph" className="mt-2">
          Type the amount of money
        </Typography>
        <div className="my-4">
          <Input
            name="moneyAmount"
            onChange={(e: any) => {
              setMoneyAmount(e.target.value);
            }}
            crossOrigin={moneyAmount.toString()}
            label="Amount of Money"
          />
        </div>
        <div className="mt-6 flex gap-2 justify-center">
          <Button
            size="sm"
            variant="outlined"
            onClick={() => {
              cancel();
            }}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            onClick={() => {
              callback(moneyAmount);
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}

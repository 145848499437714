import { Button, Input, Typography } from "@material-tailwind/react";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CartContext } from "src/context/CartContext";
import { OrderContext } from "src/context/OrderContext";
import { Order, ProductCart } from "src/types/init";

export function MenuProductInformationPage() {
  const navigate = useNavigate();

  const { cart } = useContext(CartContext);
  const { order, editOrder } = useContext(OrderContext);

  const [information, setInformation] = useState<Order>({
    tableNumber: "-",
    orderType: "DINE_IN",
    customerPhone: "",
    memberCardNumber: "",
    name: "",
    orderInput: [],
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInformation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let digitalProductStatus = false;

    const orderInput = cart.products.map((product: ProductCart) => {
      if (product.product.digitalProduct) {
        digitalProductStatus = true;
      }
      return {
        product: product.product,
        variant: product.product.variants?.find(
          (variant) => variant.id == product.variant
        ),
        quantity: product.qty,
        notes: product.note,
      };
    });

    if (digitalProductStatus && information.memberCardNumber == "") {
      toast.error(
        "Digital product can't be ordered without member card number"
      );
      return;
    }

    const orderTemp = {
      ...information,
      orderInput: orderInput,
    };

    editOrder(orderTemp);

    navigate("/dashboard/menu/product/payment");
  };

  return (
    <div className="flex flex-col gap-12 min-h-[calc(100vh-200px)]">
      <div className="grid grid-cols-12 gap-4 mb-8">
        <div className="bg-white shadow-lg rounded-xl p-10 col-span-6">
          <Typography variant="h6" color="gray">
            Information Customer
          </Typography>
          <form className="mt-8 mb-2" onSubmit={handleSubmit}>
            <div className="mb-1 flex flex-col gap-5 lg:col-span-1 col-span-2">
              <Input
                name="orderType"
                value={information.orderType}
                crossOrigin={information.orderType.toString()}
                label="Order Type"
                readOnly={true}
                onChange={handleChange}
              />

              <Input
                name="name"
                value={information.name}
                crossOrigin={information.name.toString()}
                label="Name"
                onChange={handleChange}
              />
            </div>

            <div className="flex mt-8 gap-4">
              <Button size="lg" type="submit">
                Payment
              </Button>

              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import { CombineComponents } from "./CombineComponents";
import { UserProvider } from "./UserContext";
import { CartProvider } from "./CartContext";
import { OrderProvider } from "./OrderContext";
import { ProductProvider } from "./ProductContect";
import { RouteProvider } from "./RouteContext";

const providers = [
  UserProvider,
  CartProvider,
  OrderProvider,
  ProductProvider,
  RouteProvider,
];
export const AppContextProvider = CombineComponents(...providers);

import axios from "axios";
import { REACT_APP_GRAPHQL_API_URL } from "src/configs/url";
import { handleAxiosError } from "src/services/errors";
import { headersApiKey } from "src/services/config";
import { GET_SUBMENUS } from "../query/Submenu";

export class SubmenuGraphServices {
  async gets({ menuId }: { menuId: string }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: GET_SUBMENUS, variables: { menuId } },
        {
          headers: headersApiKey,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}

import axios from "axios";
import { REACT_APP_GRAPHQL_API_URL } from "src/configs/url";
import { handleAxiosError } from "src/services/errors";
import { headersApiKey } from "src/services/config";
import { CREATE_QRIS_TRANSACTION } from "../mutation/CreateQrisTransaction";
import { IS_TRANSACTION_SETTLED } from "../query/IsTransactionSettled";

export class TransactionGraphServices {
  async createQrisTransaction({ orderCustomerId }: { orderCustomerId: string }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: CREATE_QRIS_TRANSACTION, variables: { input: orderCustomerId } },
        {
          headers: headersApiKey,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  async isTransactionSettled({ id }: { id: string }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: IS_TRANSACTION_SETTLED, variables: { isTransactionSettledId: id,} },
        {
          headers: headersApiKey,
        }
      );

      return response.data.data;
    } catch (err) {
      handleAxiosError(err);
      return;
    }
  }
}

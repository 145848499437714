import axios from "axios";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";
import { REACT_APP_API_URL } from "src/configs";

export class AuthServices {
    async SignIn({ email, password }: { email: string; password: string }) {
        try {
          const res = await axios.post(`${REACT_APP_API_URL}/users/sign_in`, {
            api_user: {
              email: email,
              password: password,
            },
          });
          if (res.status === 200) {
            return res.data;
          } else {
            handleOtherStatusCodes(res.status);
            return false;
          }
        } catch (err) {
          handleAxiosError(err);
          return false;
        }
      }
}
